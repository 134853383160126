/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

// import Header from "./header"
import "./layout.css"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: 960,
                    paddingTop: 0,
                }}
            >
                <main className='main'>{children}</main>
                {/*<footer>*/}
                {/*  © {new Date().getFullYear()}, Powered by*/}
                {/*  {` `}*/}
                {/*  <a href="http://www.mybdai.com">BDAI</a>*/}
                {/*</footer>*/}
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
